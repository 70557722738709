/* Lightbox Modal */
.lightbox .modal-dialog {
    @apply flex min-h-[calc(100%_-_6rem)] max-w-5xl;
}

.lightbox .modal-content {
    @apply w-full overflow-hidden;
}

.lightbox .ratio-16x9 {
    --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.lightbox .btn-close {
    @apply !bg-jacarta-900/30 right-2 top-2 rounded-full;
}

.lightbox .btn-close svg {
    @apply !top-0 h-3 w-3;
}

.lightbox .modal-dialog {
    display: flex;
    max-width: 64rem;
    min-height: calc(100% - 6rem);
}

.lightbox .modal-content {
    overflow: hidden;
    width: 100%;
}

.lightbox .ratio-16x9 {
    --bs-aspect-ratio: 56.25%;
}

.lightbox .btn-close {
    background-color: rgba(13, 16, 45, 0.3) !important;
    border-radius: 9999px;
    right: 0.5rem;
    top: 0.5rem;
}

.lightbox .btn-close svg {
    height: 0.75rem;
    top: 0 !important;
    width: 0.75rem;
}

/* Video Lightbox Modal */
.video-lightbox .modal-content {
    @apply w-full overflow-hidden;
}

.video-lightbox .btn-close {
    @apply right-2 top-2 rounded-full !bg-white/80 p-2;
}

.video-lightbox .btn-close svg {
    @apply !top-0 h-6 w-6;
}