.background-animate {
  background-size: 400%;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.rc-md-editor {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden;
}

.rc-md-editor .rc-md-navigation {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.button-type-fullscreen {
  display: none !important;
}

.rc-md-editor .editor-container .sec-html {
  border-style: none;
}

.section-container {
  background: transparent !important;
}

.sec-html {
  display: none;
}

.button-type-mode {
  display: none !important;
}

.button-type-clear {
  display: none !important;
}

.button-type-code-block,
.button-type-code-inline,
.button-type-underline,
.button-type-wrap,
.button-type-quote {
  display: none !important;
}

.rc-md-editor .rc-md-navigation {
  background-color: #e7e8ec;
}

.rc-md-editor .editor-container .sec-md {
  border-style: none;
}

.dark .rc-md-editor .editor-container .sec-md .input {
  background: rgb(239 235 199) !important;
}

.dark .rmel-iconfont {
  color: black !important;
}

.dark .rc-md-editor {
  border-color: #3b3a395c;
  border-width: 1px;
}

.dark .rc-md-editor .rc-md-navigation {
  background: #c9c5a1;
}

.button-type-strikethrough {
  display: none !important;
}

.button-type-ordered {
  display: none !important;
}

.custom-html-style ol,
.custom-html-style ul {
  list-style: disc !important;
}

.range-slider .range-slider__range {
  background-color: #9d7cfe;
}

.range-slider .range-slider__thumb {
  background-color: #7445ff;
}

.bounce {
  overflow: hidden;
  position: relative;
}

.bounce-20 {
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  z-index: 9999;
  text-wrap: nowrap;

  /* Apply animation to this element */
  -moz-animation: bouncing-text-20 5s linear infinite alternate;
  -webkit-animation: bouncing-text-20 5s linear infinite alternate;
  animation: bouncing-text-20 5s linear infinite alternate;
}

.bounce-40 {
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  z-index: 9999;
  text-wrap: nowrap;

  /* Apply animation to this element */
  -moz-animation: bouncing-text-40 5s linear infinite alternate;
  -webkit-animation: bouncing-text-40 5s linear infinite alternate;
  animation: bouncing-text-40 5s linear infinite alternate;
}

.bounce-60 {
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  z-index: 9999;
  text-wrap: nowrap;

  /* Apply animation to this element */
  -moz-animation: bouncing-text-60 5s linear infinite alternate;
  -webkit-animation: bouncing-text-60 5s linear infinite alternate;
  animation: bouncing-text-60 5s linear infinite alternate;
}

.bounce-80 {
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  z-index: 9999;
  text-wrap: nowrap;

  /* Apply animation to this element */
  -moz-animation: bouncing-text-80 5s linear infinite alternate;
  -webkit-animation: bouncing-text-80 5s linear infinite alternate;
  animation: bouncing-text-80 5s linear infinite alternate;
}

.bounce-100 {
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  z-index: 9999;
  text-wrap: nowrap;

  /* Apply animation to this element */
  -moz-animation: bouncing-text-100 5s linear infinite alternate;
  -webkit-animation: bouncing-text-100 5s linear infinite alternate;
  animation: bouncing-text-100 5s linear infinite alternate;
}

:root {
  --bounce-start-pos-20: -20%;
  --bounce-end-pos-20: 0;
  --bounce-start-pos-40: -40%;
  --bounce-end-pos-40: 0;
  --bounce-start-pos-60: -60%;
  --bounce-end-pos-60: 0;
  --bounce-start-pos-80: -80%;
  --bounce-end-pos-80: 0;
  --bounce-start-pos-100: -100%;
  --bounce-end-pos-100: 0;
}

/* Move it (define the animation) 20 */
@-moz-keyframes bouncing-text-20 {
  0% {
    -moz-transform: translateX(var(--bounce-start-pos-20));
  }
  50% {
    -moz-transform: translateX(var(--bounce-end-pos-20));
  }
  100% {
    -moz-transform: translateX(var(--bounce-end-pos-20));
  }
}
@-webkit-keyframes bouncing-text-20 {
  0% {
    -webkit-transform: translateX(var(--bounce-start-pos-20));
  }
  50% {
    -webkit-transform: translateX(var(--bounce-end-pos-20));
  }
  100% {
    -webkit-transform: translateX(var(--bounce-end-pos-20));
  }
}
@keyframes bouncing-text-20 {
  0% {
    -moz-transform: translateX(var(--bounce-start-pos-20));
    -webkit-transform: translateX(var(--bounce-start-pos-20));
    transform: translateX(var(--bounce-start-pos-20));
  }
  50% {
    -moz-transform: translateX(var(--bounce-end-pos-20));
    -webkit-transform: translateX(var(--bounce-end-pos-20));
    transform: translateX(var(--bounce-end-pos-20));
  }
  100% {
    -moz-transform: translateX(var(--bounce-end-pos-20));
    -webkit-transform: translateX(var(--bounce-end-pos-20));
    transform: translateX(var(--bounce-end-pos-20));
  }
}

/* Move it (define the animation) 20 */
@-moz-keyframes bouncing-text-40 {
  0% {
    -moz-transform: translateX(var(--bounce-start-pos-40));
  }
  50% {
    -moz-transform: translateX(var(--bounce-end-pos-40));
  }
  100% {
    -moz-transform: translateX(var(--bounce-end-pos-40));
  }
}
@-webkit-keyframes bouncing-text-40 {
  0% {
    -webkit-transform: translateX(var(--bounce-start-pos-40));
  }
  50% {
    -webkit-transform: translateX(var(--bounce-end-pos-40));
  }
  100% {
    -webkit-transform: translateX(var(--bounce-end-pos-40));
  }
}
@keyframes bouncing-text-40 {
  0% {
    -moz-transform: translateX(var(--bounce-start-pos-40));
    -webkit-transform: translateX(var(--bounce-start-pos-40));
    transform: translateX(var(--bounce-start-pos-40));
  }
  50% {
    -moz-transform: translateX(var(--bounce-end-pos-40));
    -webkit-transform: translateX(var(--bounce-end-pos-40));
    transform: translateX(var(--bounce-end-pos-40));
  }
  100% {
    -moz-transform: translateX(var(--bounce-end-pos-40));
    -webkit-transform: translateX(var(--bounce-end-pos-40));
    transform: translateX(var(--bounce-end-pos-40));
  }
}

/* Move it (define the animation) 20 */
@-moz-keyframes bouncing-text-60 {
  0% {
    -moz-transform: translateX(var(--bounce-start-pos-60));
  }
  50% {
    -moz-transform: translateX(var(--bounce-end-pos-60));
  }
  100% {
    -moz-transform: translateX(var(--bounce-end-pos-60));
  }
}
@-webkit-keyframes bouncing-text-60 {
  0% {
    -webkit-transform: translateX(var(--bounce-start-pos-60));
  }
  50% {
    -webkit-transform: translateX(var(--bounce-end-pos-60));
  }
  100% {
    -webkit-transform: translateX(var(--bounce-end-pos-60));
  }
}
@keyframes bouncing-text-60 {
  0% {
    -moz-transform: translateX(var(--bounce-start-pos-60));
    -webkit-transform: translateX(var(--bounce-start-pos-60));
    transform: translateX(var(--bounce-start-pos-60));
  }
  50% {
    -moz-transform: translateX(var(--bounce-end-pos-60));
    -webkit-transform: translateX(var(--bounce-end-pos-60));
    transform: translateX(var(--bounce-end-pos-60));
  }
  100% {
    -moz-transform: translateX(var(--bounce-end-pos-60));
    -webkit-transform: translateX(var(--bounce-end-pos-60));
    transform: translateX(var(--bounce-end-pos-60));
  }
}

/* Move it (define the animation) 20 */
@-moz-keyframes bouncing-text-80 {
  0% {
    -moz-transform: translateX(var(--bounce-start-pos-80));
  }
  50% {
    -moz-transform: translateX(var(--bounce-end-pos-80));
  }
  100% {
    -moz-transform: translateX(var(--bounce-end-pos-80));
  }
}
@-webkit-keyframes bouncing-text-80 {
  0% {
    -webkit-transform: translateX(var(--bounce-start-pos-80));
  }
  50% {
    -webkit-transform: translateX(var(--bounce-end-pos-80));
  }
  100% {
    -webkit-transform: translateX(var(--bounce-end-pos-80));
  }
}
@keyframes bouncing-text-80 {
  0% {
    -moz-transform: translateX(var(--bounce-start-pos-80));
    -webkit-transform: translateX(var(--bounce-start-pos-80));
    transform: translateX(var(--bounce-start-pos-80));
  }
  50% {
    -moz-transform: translateX(var(--bounce-end-pos-80));
    -webkit-transform: translateX(var(--bounce-end-pos-80));
    transform: translateX(var(--bounce-end-pos-80));
  }
  100% {
    -moz-transform: translateX(var(--bounce-end-pos-80));
    -webkit-transform: translateX(var(--bounce-end-pos-80));
    transform: translateX(var(--bounce-end-pos-80));
  }
}

/* Move it (define the animation) 20 */
@-moz-keyframes bouncing-text-100 {
  0% {
    -moz-transform: translateX(var(--bounce-start-pos-100));
  }
  50% {
    -moz-transform: translateX(var(--bounce-end-pos-100));
  }
  100% {
    -moz-transform: translateX(var(--bounce-end-pos-100));
  }
}
@-webkit-keyframes bouncing-text-100 {
  0% {
    -webkit-transform: translateX(var(--bounce-start-pos-100));
  }
  50% {
    -webkit-transform: translateX(var(--bounce-end-pos-100));
  }
  100% {
    -webkit-transform: translateX(var(--bounce-end-pos-100));
  }
}
@keyframes bouncing-text-100 {
  0% {
    -moz-transform: translateX(var(--bounce-start-pos-100));
    -webkit-transform: translateX(var(--bounce-start-pos-100));
    transform: translateX(var(--bounce-start-pos-100));
  }
  50% {
    -moz-transform: translateX(var(--bounce-end-pos-100));
    -webkit-transform: translateX(var(--bounce-end-pos-100));
    transform: translateX(var(--bounce-end-pos-100));
  }
  100% {
    -moz-transform: translateX(var(--bounce-end-pos-100));
    -webkit-transform: translateX(var(--bounce-end-pos-100));
    transform: translateX(var(--bounce-end-pos-100));
  }
}
