.benifit .react-tabs__tab--selected {
    background: transparent;
}

.benifit .react-tabs__tab--selected button {
    --tw-bg-opacity: 1;
    --tw-shadow: 0px 25px 50px -12px rgba(13, 16, 45, 0.1), 0px 12px 24px 0px rgba(13, 16, 45, 0.1);
    --tw-shadow-colored: 0px 25px 50px -12px var(--tw-shadow-color), 0px 12px 24px 0px var(--tw-shadow-color);
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    border-bottom-width: 0;
    border-radius: 1.25rem;
    box-shadow: 0 0 transparent, 0 0 transparent, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent), var(--tw-ring-shadow, 0 0 transparent),
        var(--tw-shadow);
}

.dark .benifit .react-tabs__tab--selected button {
    --tw-bg-opacity: 1;
    background-color: rgba(19, 23, 64, var(--tw-bg-opacity));
}

.benifit .react-tabs__tab--selected button::after {
    content: var(--tw-content);
    display: none;
}

.benifit .react-tabs__tab--selected .hidden {
    display: block;
}